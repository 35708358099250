import Markdown from 'react-markdown';
import { graphql } from 'gatsby';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MainLayout from 'layouts/Main';
class BlogPostTemplate extends Component {
  render() {
    return (
      <MainLayout>
        <section className='section'>
          <div className='container content'>
            <div className='columns'>
              <div className='column is-10 is-offset-1'>
                <h1 className='title is-size-2 has-text-weight-bold is-bold-light'>
                  {this.props.title}
                </h1>
                <h3>{this.props.date}</h3>
                <p>{this.props.description}</p>
                <Markdown source={this.props.content} />
              </div>
            </div>
          </div>
        </section>
      </MainLayout>
    );
  }
}

BlogPostTemplate.propTypes = {
  content: PropTypes.string.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
};

const BlogPost = ({ data }) => {
  const { allBlogJson: post } = data;
  return (
    <BlogPostTemplate
      content={post.edges[0].node.body}
      date={post.edges[0].node.date}
      description={post.edges[0].node.description}
      title={post.edges[0].node.title}
    />
  );
};

export default BlogPost;

export const query = graphql`
  query($slug: String!) {
    allBlogJson(filter: { fields: { slug: { eq: $slug } } }) {
      edges {
        node {
          id
          templateKey
          title
          description
          body
          image
          date(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`;
